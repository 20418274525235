<script setup lang="ts"></script>

<template>
  <NuxtLayout name="panel">
    <main class="admin-layout">
      <AtomsTitle label="Configurações" type="page" class="" />

      <!-- CONTEUDO DA ROTA ATUAL -->
      <div class="admin-layout__content">
        <NuxtPage />
      </div>
    </main>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
.admin-layout {
  @apply w-full flex flex-col;

  &__content {
    @apply mt-[45px] flex flex-col;
  }
}
</style>
